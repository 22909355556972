import { createAction, props } from '@ngrx/store';

export const setDgUseInitiatePayout = createAction(
  '[FeatureFlag] Set the dgUseInitiatePayout boolean',
  props<{ dgUseInitiatePayout: boolean }>(),
);

export const setDgExerciseServiceDisruptionHyperwallet = createAction(
  '[FeatureFlag] Set DG Exercise Service Disruption Hyperwallet',
  props<{ dgExerciseServiceDisruptionHyperwallet: boolean }>(),
);
