import { Action, createReducer, on } from '@ngrx/store';
import * as featureFlagActions from './feature-flag.actions';
import * as FeatureFlagActions from './feature-flag.actions';

export interface FeatureFlagState {
  dgUseInitiatePayout: boolean;
  dgExerciseServiceDisruptionHyperwallet: boolean;
}

const initialState: FeatureFlagState = {
  dgUseInitiatePayout: false,
  dgExerciseServiceDisruptionHyperwallet: false,
};

const reducer = createReducer(
  initialState,
  on(
    featureFlagActions.setDgUseInitiatePayout,
    (state, { dgUseInitiatePayout }) => ({
      ...state,
      dgUseInitiatePayout,
    }),
  ),
  on(
    FeatureFlagActions.setDgExerciseServiceDisruptionHyperwallet,
    (state, { dgExerciseServiceDisruptionHyperwallet }) => ({
      ...state,
      dgExerciseServiceDisruptionHyperwallet,
    }),
  ),
);

export function featureFlagReducer(
  state: FeatureFlagState | undefined,
  action: Action,
): FeatureFlagState {
  return reducer(state, action);
}
