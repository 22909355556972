export enum ExerciseActionStep {
  SEND_VERIFICATION_STEP = 'Send verification code',
  CHECK_VERIFICATION_STEP = 'Check verification code',
  CHOICE_STEP = 'Choice',

  // REFUND
  REFUND_HW_FORM_STEP = 'Hyperwallet Form Step',
  REFUND_HW_REFUND_STEP = 'Hyperwallet Refund Step',
  REFUND_SL_FORM_STEP = 'Spreedly Form Step',
  REFUND_BT_FORM_STEP = 'Bank Transfer Form Step',
  REFUND_CONFIRMATION_STEP = 'Refund Confirmation Step',
  PAYOUT_ERROR_STEP = 'Payout Error Step',

  // REBOOKING
  REBOOKING_SEARCH_STEP = 'Rebooking Search Step',
  REBOOKING_REVIEW_STEP = 'Rebooking Review Step',
  REBOOKING_FORM_STEP = 'Rebooking Form Step',
  REBOOKING_CONFIRMATION_STEP = 'Rebooking Confirmation Step',
}
